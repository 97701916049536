<template>
	<div id="dashboard-partner-finance-component">
		<v-card flat class="profit_card pa-2 ma-0 rounded-lg" color="#e1f8e6">
			<v-card-title>
				<h4 class="green--text">Order Sales</h4>
			</v-card-title>
			<v-card-text>
				<h2 class="mt-2 green--text">{{ dashboard_data.order_sales }}</h2>
			</v-card-text>
		</v-card>
		<v-card class="px-2 mt-3 py-0 mt-2 eleveation-6	rounded-lg">
			<v-card-title class="display-2 font-weight-light mb-4">
				<v-col class="ma-0">
					<v-card flat class="ma-0 rounded-lg">
						<v-row class="pa-0 ma-0" dense>
							<v-col cols="6" class="">
								<v-card-title>
									<h4>Credited Limit</h4>
								</v-card-title>
								<v-card-text>
									<h4 class="mt-2 blue--text">
										{{ dashboard_data.credit_amount }} SAR
									</h4>
								</v-card-text>
							</v-col>

							<v-col cols="6" style="border-left: solid 1px #ddd">
								<v-card-title>
									<h4>Advance Payment</h4>
								</v-card-title>
								<v-card-text>
									<h4 class="mt-2">
										{{ dashboard_data.advance_payments }} SAR
									</h4>
								</v-card-text>
							</v-col>
							<v-col>
								<v-divider class="grey"></v-divider>
							</v-col>
							<v-col cols="12" class=" ">
								<v-card-title>
									<h4>Credit Limit</h4>
								</v-card-title>

								<apexchart
									type="donut"
									height="300"
									:options="my_wallet_chart.chartOptions"
									:series="my_wallet_chart.series"
								></apexchart>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-card-title>
		</v-card>
	</div>
</template>

<script>
export default {
	props: ["dashboard_data"],
	data() {
		return {
			my_wallet_chart: {
				series: [
					this.dashboard_data.available_credits,
					this.dashboard_data.advance_payments
				],

				chartOptions: {
					chart: {
						type: "donut",
						toolbar: false
					},
					colors: ["#E0E0E0", "#5F2EE9"],
					responsive: [
						{
							// breakpoint: 480,
							// options: {
							// 	chart: {}
							// }
						}
					],
					legend: {
						position: "bottom",
						offsetY: 5,
						height: 20,
						itemMargin: {
							horizontal: 20
						}
					},
					labels: [
						"Available " + this.dashboard_data.available_credits,
						"Consumed " + this.dashboard_data.advance_payments
					],
					plotOptions: {
						pie: {
							dataLabels: {
								offset: 0,
								minAngleToShowLabel: 10
							},
							donut: {
								size: "75%"
							}
						}
					}
				},
				markers: {
					offsetX: 50
				}
			}
		};
	}
};
</script>

<style scoped>
.v-progress-circular {
	margin: 1rem;
}
.lab,
p {
	color: #ffffff !important;
	font-size: 16px;
}
/* Temporary workaound v-card default  */
.profit_card {
	border: 1px solid rgb(0, 212, 46) !important;
	background-image: url(../../../../assets/money.png);
	background-position: right;
}
</style>
