<template>
	<v-container id="dashboard-total-orders-component" class="-d-flex ">
		<v-row>
			<v-col>
				<span class="grey--text text-h3">Total Orders</span>

				<apexchart
					type="bar"
					height="550"
					:options="chartOptions"
					:series="series"
				>
				</apexchart>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	mounted() {},
	props: ["series"],
	data() {
		return {
			chartOptions: {
				chart: {
					id: "dashboard-total-orders-chart"
				},
				xaxis: {
					categories: [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec"
					]
				},
				plotOptions: {
					bar: {
						columnWidth: "100%"
					}
				}
			}
		};
	},
	computed: {},
	watch: {},
	methods: {},
	mounted() {}
};
</script>

<style scoped></style>
