<template>
  <v-row id="dashboard-best-seller-component">
    <v-col cols="12">
      <v-card class="px-8 pb-8 pt-2">
        <v-row>
          <v-col cols="12">
            <v-card class="py-0" flat>
              <v-card-title class="display-2 font-weight-light mb-4">
                <v-row>
                  <v-col cols="6">
                    <v-label>
                      <h1 style="color: grey">Best seller last month</h1>
                    </v-label>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      to="/dashboard/best-sellers"
                      class="munjz-accent-light rounded-pill primary--text"
                      text
                      ><h2>See more sellers</h2>
                      <v-icon>mdi-menu-right</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-radio-group
                      v-model="best_seller_by"
                      row
                      @change="sellerByChanged($event)"
                    >
                      <v-radio label="Orders" value="1"></v-radio>
                      <v-radio label="Sales" value="2"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-simple-table id="dashboard-best-seller-table">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">ID</th>
                            <th class="text-left">Name</th>
                            <th class="text-left">Current Month</th>
                            <th class="text-left">Total Orders</th>
                            <th class="text-left">Total Orders Sales</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(seller, index) in best_sellers"
                            :key="index"
                          >
                            <td>{{ seller.id }}</td>
                            <td>{{ seller.name }}</td>
                            <td>{{ current_month }}</td>
                            <td>{{ seller.seller_count }}</td>
                            <td>{{ seller.partner_total_sale_with_vat }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["best_sellers_by_orders", "best_sellers_by_sales", "current_month"],
  data() {
    return {
      best_seller_by: "1",
      best_sellers: []
    };
  },
  watch: {},
  methods: {
    sellerByChanged(e) {
      if (e == 1) {
        this.best_sellers = this.best_sellers_by_orders;
      } else {
        this.best_sellers = this.best_sellers_by_sales;
      }
    }
  },
  mounted() {
    this.best_sellers = this.best_sellers_by_orders;
  }
};
</script>

<style scoped>
#dashboard-best-seller-table th {
  color: #000 !important;
}
</style>
