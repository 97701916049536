<template>
	<v-row id="dashboard-branches-charts-component">
		<v-col cols="12" sm="6" v-if="hasPermission('Branches Per Order')">
			<v-card class="px-8 pb-8 pt-2">
				<v-row>
					<v-col cols="12">
						<v-card class="px-5 py-0" flat>
							<v-card-title class="display-2 font-weight-light mb-4">
								<v-label>
									<h1 style="color:grey">Branches per orders</h1>
									<!--                                    <span v-if="filterRangeText" v-html="filterRangeText"></span>-->
								</v-label>
							</v-card-title>

							<div id="branches-chart1">
								<apexchart
									type="line"
									height="350"
									:options="branchOrdersChartOptions"
									:series="branch_orders_chart_data"
								></apexchart>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
		<v-col cols="12" sm="6">
			<v-card class="px-8 pb-8 pt-2" v-if="hasPermission('Branches Per Sales')">
				<v-row>
					<v-col cols="12">
						<v-card class="px-5 py-0" flat>
							<v-card-title class="display-2 font-weight-light mb-4">
								<v-label>
									<h1 style="color:grey">Branches per Sales</h1>
									<!--                                    <span v-if="filterRangeText" v-html="filterRangeText"></span>-->
								</v-label>
							</v-card-title>

							<div id="branches-chart">
								<apexchart
									type="line"
									height="350"
									:options="branchSalesChartOptions"
									:series="branch_sales_chart_data"
								></apexchart>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: ["branch_sales_chart_data", "branch_orders_chart_data"],
	data() {
		return {
			branchSalesChartOptions: {
				chart: {
					height: 350,
					width: 300,
					type: "line"
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: "straight"
				},
				title: {
					text: "Product Trends by Month",
					align: "left"
				},
				grid: {
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5
					}
				},
				xaxis: {
					categories: [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec"
					]
				}
			},
			branch_orders_series: [],
			branchOrdersChartOptions: {
				chart: {
					height: 350,
					width: 300,
					type: "line"
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: "straight"
				},
				title: {
					text: "Product Trends by Month",
					align: "left"
				},
				grid: {
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5
					}
				},
				xaxis: {
					categories: [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec"
					]
				}
			}
		};
	},
	mounted() {
		this.getAdminPermissions();
	},
	created() {
		this.branch_sales_series = this.branch_sales_chart_data;
		this.branch_orders_series = this.branch_orders_chart_data;
	}
};
</script>

<style scoped>
</style>
