<template>
  <!-- eslint-disable -->
  <v-container
    v-if="hasPermission($route.meta.permission)"
    id="dashboard"
    tag="section"
  >
    <v-row>
      <v-col cols="12"><span class="text-h2">Dashboard</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <span class="grey--text text-h4 ">Filters</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" class=" px-0">
        <v-menu
          ref="dialog"
          v-model="dateRangeMenue"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          min-width="auto"
          offset-y
          dense
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              readonly
              range
              label="Pick a date range"
              prepend-inner-icon="mdi-calendar"
              class="white"
              dense
              :append-icon="dateRangeText && 'mdi-close'"
              @click:append="ClearDateFilter"
              outlined
              hide-details
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:prepend-inner
                ><v-icon>mdi-calendar</v-icon></template
              >
            </v-text-field>
          </template>
          <v-date-picker
            class="ma-0"
            color="munjz-accent"
            v-model="filterDateRange"
            range
            @change="filterDateRangeChanged($event)"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateRangeMenue = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="dateRangeMenue = false">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="selectedBranches"
          :items="branchList"
          outlined
          dense
          multiple
          deletable-chips
          small-chips
          label="Filter by branch"
          item-text="name"
          item-value="id"
          @change="branchChanged"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" v-if="hasPermission('Total Order Chart')" sm="8">
        <dashboard-total-orders
          v-if="dashboardData"
          :series="series3"
        ></dashboard-total-orders>
      </v-col>
      <v-col
        class=""
        cols="12"
        :sm="!hasPermission('Total Order Chart') ? '12' : '4'"
      >
        <dashboard-partner-finance
          v-if="dashboardData"
          :dashboard_data="dashboardData"
        ></dashboard-partner-finance>
      </v-col>
    </v-row>

    <v-row>
      <dashboard-branches-charts
        v-if="dashboardData"
        :branch_sales_chart_data="dashboardData.branch_sales_chart_data"
        :branch_orders_chart_data="dashboardData.branch_orders_chart_data"
      ></dashboard-branches-charts>
    </v-row>
    <v-row>
      <dashboard-best-seller
        v-if="dashboardData && hasPermission('Best Sellers')"
        :best_sellers_by_orders="dashboardData.best_sellers_by_orders"
        :best_sellers_by_sales="dashboardData.best_sellers_by_sales"
        :current_month="dashboardData.current_month"
      ></dashboard-best-seller>
    </v-row>
  </v-container>
  <v-container fluid v-else class="pa-10 " id="regular-tables" tag="section">
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-card flat>
          <v-card-title
            ><v-card class="red">
              <!-- <h1 class="red pa-6">
                You Don't have Permission to view this page !
              </h1> -->
            </v-card></v-card-title
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import DashboardTotalOrders from "./DashboardTotalOrders";
import DashboardPartnerFinance from "./DashboardPartnerFinance";
import DashboardBranchesCharts from "./DashboardBranchesCharts";
import DashboardBestSeller from "./DashboardBestSeller";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
export default {
  components: {
    DashboardTotalOrders,
    DashboardPartnerFinance,
    DashboardBranchesCharts,
    DashboardBestSeller
  },
  data() {
    return {
      items: ["foo", "bar", "fizz", "buzz"],
      values: ["foo", "bar"],
      branchList: [],
      date: null,
      selectedBranches: [],
      customFilterVisible: false,
      total_sales: 0,
      filterFromDialog: false,
      filterToDialog: false,
      filterTo: "",
      filterFrom: "",
      Available: 0,
      spent: [],
      detail: [],
      dashboardData: null,
      topServ: [],
      skill: 20,
      knowledge: 33,
      power: 78,
      skil: 5000,
      knowled: 33,
      pow: 78,
      show: false,
      interval: {},
      value: 0,
      radioGroup: 0,
      filterDateRange: [],
      date: null,
      from: null,
      to: null,
      dateRangeMenue: false,
      user_id: localStorage.getItem("userid"),
      token: localStorage.getItem("token"),
      month: moment().format("MMMM YYYY"),
      now: moment().format("MMMM Do YYYY"),
      counters: [],
      all_orders: 0,
      ongoing_orders: 0,
      completed_orders: 0,
      total_spent: 0,
      series1: [],
      series2: [],
      ordersCategory: [],
      ordersbranch: []
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    filterRangeText() {
      if (this.filterFrom && this.filterTo) {
        return `<h3 class="mt-2">(<small>From: ${this.filterFrom}, To: ${this.filterTo}</small>)</h3>`;
      }
      return null;
    },
    dateRangeText() {
      // return `from ${this.filterDateRange[0]} to ${this.filterDateRange[1]}`;
      return this.filterDateRange.join(" to ");
    }
  },
  watch: {
    filterFrom: {
      handler() {
        this.filterDateChange();
      },
      deep: true
    },
    filterTo: {
      handler() {
        this.filterDateChange();
      },
      deep: true
    },
    dashboardData: {
      handler() {
        // this.chartOptions = {  xaxis: {categories: this.ordersCategory.Category}};
        this.series3 = [
          // { name: "Total sales", data: [0, 0, 13800] }, //this.dashboardData.sales_cost_per_month.data
          {
            name: "Total Orders",
            data: this.dashboardData.total_orders_per_month.data
          },
          {
            name: "Open Orders",
            data: this.dashboardData.open_orders_per_month.data
          },
          {
            name: "Completed Orders",
            data: this.dashboardData.completed_orders_per_month.data
          }
        ];
        // this.series = this.ordersdata.series.orders;
        // this.series2 = this.ordersdata.series.orders_sales;
      },
      deep: true
    }
  },
  mounted() {
    this.getAdminPermissions();

  },
  created() {
    this.getDashboardData();
    this.getBranches();
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 1000);
  },
  methods: {
    ClearDateFilter() {
      this.dateRangeMenue = false;
      this.filterDateRange = [];
      this.filterDateChange();
    },
    filterDateText(payload) {
      if (payload == "last_week") {
        this.filterFrom = moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
        this.filterTo = moment().format("YYYY-MM-DD");
      } else if (payload == "last_month") {
        this.filterFrom = moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD");
        this.filterTo = moment().format("YYYY-MM-DD");
      } else if (payload == "custom") {
        this.customFilterVisible = true;
      }
      this.getDashboardData();
    },
    getDashboardData() {
      axios
        .get("/api/v3/b2b/dashboard", {
          headers: { "X-Authorization": this.token },
          params: {
            from: this.from,
            to: this.to,
            branch_id: this.selectedBranches
          }
        })
        .then(res => {
          // this.filterFrom = res.data.from_date;
          // this.filterTo = res.data.to_date;
          // this.filterDateRange = [res.data.from_date, res.data.to_date];
          this.all_orders = res.data.all_orders;
          this.ongoing_orders = res.data.ongoing_orders;
          this.completed_orders = res.data.completed_orders;
          this.total_spent = res.data.partner_detail.credit_spent;
          this.detail = res.data.top_branches;
          this.topServ = res.data.top_services;
          this.total_sales = res.data.total_sales;
          this.dashboardData = res.data;
          this.Available = res.data.partner_detail.credit_balance;
          this.available_credits = res.data.available_credits;
          this.spent = res.data.partner_detail;
          this.ordersCategory = res.data.dashboard_category;
          this.ordersbranch = res.data.dashboard_branch;
        })
        .catch(err => console.log(err.response.data));
    },
    getBranches() {
      axios
        .get("/api/v3/b2b/partner/branches-list", {
          headers: { "X-Authorization": this.token },
          params: {
            // partner_id:this.parent_id == 0 ?  this.user_id : this.parent_id,
            // seller_id:  this.parent_id == 0 ?  null : this.user_id ,
            user_id: this.user_id
          }
        })
        .then(res => {
          this.branchList = res.data.branches;
          if (this.branchList.length > 0) {
            // this.selectedBranches = this.branchList[0].identifier;
          }
        })
        .catch(err => console.log(err.response.data));
    },
    filterDateRangeChanged(newDateRange) {
      if (newDateRange.length == 1) {
        this.from = newDateRange[0];
      } else if (newDateRange.length == 2) {
        this.from = newDateRange[0];
        this.to = newDateRange[1];
      }
      this.getDashboardData();
    },
    filterDateChange(e) {
      this.from = this.filterDateRange[0];
      this.to = this.filterDateRange[1];
      this.getDashboardData();
    },
    branchChanged() {
      this.getDashboardData();
    }
  }
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
.lab,
p {
  color: #ffffff !important;
  font-size: 16px;
}
</style>
